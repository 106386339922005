<template>
    <div class="wrap">
        <el-page-header @back="$router.back()" :content="title" class="margin-bottom margin-top-xs"></el-page-header>
        <el-alert title="操作提示" type="success" class="margin-bottom">
            <div>
                1. 标识“*”的选项为必填项，其余为选填项。<br />
                2. 请按提示栏信息进行商品添加。
            </div>
        </el-alert>
        <div class="box">
            <div class="form_box">
                <el-form ref="form" :model="form" label-width="120px" v-loading="loading">
                    <el-form-item label="客户姓名" prop="name" :rules="rules.required">
                        <el-input v-model="form.name" maxlength="30"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" :rules="rules.required">
                        <el-input v-model="form.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="咨询日期" :rules="rules.required">
                        <el-date-picker
                                v-model="form.created_at"
                                type="datetime"
                                format="yyyy 年 MM 月 dd 日 HH 时 mm 分 ss 秒"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                default-time="12:00:00"
                                placeholder="选择日期"
                                @change="getTime"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="咨询项目" :rules="rules.required">
                        <el-input v-model="form.consulting_project"></el-input>
                    </el-form-item>
                    <el-form-item label="咨询具体事项" :rules="rules.required">
                        <el-input type="textarea" v-model="form.desc"></el-input>
                    </el-form-item>
                    <el-form-item label="经办人" :rules="rules.required">
                        <el-input v-model="form.agent"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()">保存</el-button>
                        <!-- <el-button type="primary" @click="onSubmit()">保存并继续添加</el-button> -->
                        <el-button @click="$router.back()">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
    import rules from '../../../common/common.rules.js'
    export default {
        data() {
            return {
                rules,
                classify: [],
                loading: false,
                form: {
                    id: this.$route.params.id, // int 服务ID
                    name: '', // string 客户名
                    phone: '', // string 联系电话
                    desc: undefined, // string 咨询具体事项
                    created_at: '',// 咨询时间
                    consulting_project: '',// 咨询项目
                    agent: '',// 经办人
                }
            };
        },
        computed: {
            title() {
                if (this.form.id) {
                    return '编辑新客咨询'
                } else {
                    return '新增新客咨询'
                }
            }
        },
        mounted() {
            if (this.form.id) {
                this.$api.table.newConsultDetails({
                    id: this.form.id
                }).then(res => {
                    this.form = res.data
                })
            }
        },
        methods: {
            getTime(){
                console.log(this.form.created_at)
            },
            onSubmit() {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        const loading = this.$loading();
                        this.$api.table.saveNewConsult(this.form).then(res => {
                            loading.close();
                            if (res.code == 200) {
                                // 保存成功
                                this.$message.success(`保存成功`);
                                this.$router.back();
                            } else {
                                this.$message.error(res.msg);
                            }
                        }).catch(err => {
                            loading.close();
                            this.$alert(err);
                        });
                    } else {
                        this.$message.warning(`请检查输入错误`);
                        return false;
                    }
                });
            }
        },
    };
</script>

<style scoped lang="scss">
    .form_box {

        .el-input,
        .el-textarea {
            width: 500px;
        }
    }

</style>
